import { terms as T } from '../utils/bilguide';
import { UrlParamsType } from '../../../types/urlParamsType';
import { CarModelType } from '../../../types/carModelType';

const createAPIQuery = (
  params?: UrlParamsType,
  additionalFilters?: UrlParamsType['filter'],
  ids?: CarModelType['slug'][] | CarModelType['id'][],
) => {
  let queries = '';

  if (params && typeof params.query !== 'undefined') {
    queries += `&query=${encodeURI(params.query)}`;
  }

  if (params && typeof params.filter !== 'undefined') {
    Object.entries(params.filter).forEach(([key, values]) => {
      if (key === T.PRICE_GROUP && values && values.length > 0 && values[0] === '0.0-400000.0') {
        queries += `&${T.MAX_PRICE}=400000`;
      } else if (key === T.PRICE_GROUP && values && values.length > 0 && values[0] === '400000.0-600000.0') {
        queries += `&${T.MIN_PRICE}=400000&${T.MAX_PRICE}=600000`;
      } else if (key === T.PRICE_GROUP && values && values.length > 0 && values[0] === '600000.0-*') {
        queries += `&${T.MIN_PRICE}=600000`;
      } else if (typeof values !== 'undefined') {
        queries += `&${key}=${values.join(',')}`;
      }
    });
  }

  if (params && typeof params.sortBy !== 'undefined') {
    queries += `&sortBy=${params.sortBy}`;
  }

  if (typeof additionalFilters !== 'undefined') {
    Object.entries(additionalFilters).forEach(([key, values]) => {
      if (typeof values !== 'undefined') {
        queries += `&${key}=${values.join(',')}`;
      }
    });
  }
  if (typeof ids !== 'undefined') {
    queries += `&ids=${ids.join(',')}`;
  }

  return queries;
};

export const createQueryBySlug = (slugs: CarModelType['slug'][]) => `slugs=${slugs.join(',')}`;

export default createAPIQuery;
