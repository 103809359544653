import React from 'react';
import { useDispatch } from 'react-redux';
import { Search, SearchItem, SearchList } from '@naf/search';
import { actions as carFilterActions } from '../../../redux/modules/carFilter';
import useSelector from '../../../redux/typedHooks';
import { updateUrlQueryValues } from '../../../utils/updateUrlQueryValues';
import { UrlParamsType } from '../../../../../types/urlParamsType';
import createAPIQuery from '../../../lib/createURLQueries';

interface Props {
  additionalFilters?: UrlParamsType['filter'];
}

interface SearchItem {
  label: string;
  value: string;
}

const CarSearch = ({ additionalFilters }: Props) => {
  const loading = useSelector((state) => state.cars.isUpdating);
  const mappedData = useSelector((state) => state.cars.mappedData);
  const suggestionIds = useSelector((state) => state.cars.data[0]);
  const suggestions = suggestionIds.map((id) => mappedData[id].data);
  const dispatch = useDispatch();

  const handleSearchChange = async (value: string) => {
    updateUrlQueryValues.updateQueryValue(value);
    const queries = createAPIQuery({ query: value }, additionalFilters);
    dispatch(carFilterActions.getCarArguments.request(queries));
    dispatch(carFilterActions.refreshSearch());
  };

  const handleOnSuggestionClick = (suggestion: SearchItem) => {
    updateUrlQueryValues.updateQueryValue(suggestion.label);
    dispatch(carFilterActions.refreshSearch());
    const queries = createAPIQuery({ query: suggestion.label }, additionalFilters);
    dispatch(carFilterActions.getCarArguments.request(queries));
  };

  const handleSearchSubmit = (value: string) => {
    updateUrlQueryValues.updateQueryValue(value);
    dispatch(carFilterActions.refreshSearch());
    const queries = createAPIQuery({ query: value }, additionalFilters);
    dispatch(carFilterActions.getCarArguments.request(queries));
  };

  const handleClear = () => {
    updateUrlQueryValues.updateQueryValue('');
    dispatch(carFilterActions.refreshSearch());
    const queries = createAPIQuery({ query: '' }, additionalFilters);
    dispatch(carFilterActions.getCarArguments.request(queries));
  };

  return (
    <Search
      placeholder="Bilmerke, bilmodell"
      handleSubmit={handleSearchSubmit}
      handleChange={(value: string) => handleSearchChange(value)}
      handleClear={handleClear}
      withButton={false}
    >
      <SearchList loading={loading} empty={suggestions.length <= 0}>
        {suggestions &&
          suggestions.length > 0 &&
          suggestions.map(
            (s: any) =>
              !!s &&
              s && (
                <SearchItem
                  key={s.id}
                  label={`${s.brandName} ${s.modelName}`}
                  value={s.id}
                  handleClick={(suggestion: { label: string; value: string }) => handleOnSuggestionClick(suggestion)}
                />
              ),
          )}
      </SearchList>
    </Search>
  );
};

export default CarSearch;
